/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EventHandler, useEffect, useRef, useState } from "react";
import { defaultFilieres } from "../constants";
import { requestHeader } from "../../services/api/auth/useUser";
import IndicateurAnalyseButton from "../../core/components/IndicateurAnalyseButton";
import ModalAnalyse from "../../core/components/ModalAnalyse";
import Filiere from "../../models/Filiere_model";
import BookIcon from "@mui/icons-material/BookOutlined";
import MapIcon from "@mui/icons-material/MapOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { API_URL } from "../../services/api/axios";

export function useLocalStorageState(key: string, defaultValue: any = undefined) {
  const [state, setState] = useState(() => {
    let value;
    try {
      const keyValue = localStorage.getItem(key);
      if (keyValue) {
        value = JSON.parse(keyValue) || defaultValue;
      } else {
        value = defaultValue;
      }
    } catch (error) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

const excel_accept =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function downloadFile(
  endPoint: string,
  fileName: string,
  accept = excel_accept,
) {
  const headers: any = requestHeader();
  if (accept !== "") headers.Accept = accept;
  fetch(`${API_URL}${endPoint}`, { headers })
    .then((result) => {
      if (!result.ok) {
        throw Error(result.statusText);
      }
      console.log(result);
      return result.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", fileName);
      console.log(link);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export default function Navbar(props: any) {
  const { selectedFiliere, setSelectedFiliere, setSelectedIntervention } = props;

  const [openDownloads, setOpenDownloads] = useState(false);
  const [isAnalyzeOpen, setIsAnalyzeOpen] = useState(false);
  const [filiereVisible, setFiliereVisible] = useState(false);
  const [me, setMe] = useState<any>(null);

  const filieresRef = useRef<any>(null);
  const mobileMenu = useRef<any>(null);

  const [filiereComponent, setFiliereComponent] = useState<any>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const closeAnalyse = () => {
    setIsAnalyzeOpen(false);
    // setIsAnalyseOpen(false);
  };
  const openAnalyse = (filiere: Filiere) => {
    // setSelectedFiliere(filiere);
    setIsAnalyzeOpen(true);
    switchMobileMenu();
  };

  function getFiliereFromValue(value: any) {
    return defaultFilieres.find((filiere) => filiere.value === value);
  }

  const switchMobileMenu = function () {
    if (!mobileMenu.current) return;
    if (mobileMenu.current.classList.contains("group-hover:grid")) {
      mobileMenu.current.classList.remove("group-hover:grid");
    } else {
      mobileMenu.current.classList.add("group-hover:grid");
    }
  };

  const navigateAndHide = function (url: string) {
    switchMobileMenu();
    navigate(url);
  };

  return (
    <>
      <div className={`${location.pathname === "/" ? "" : ""}`}>
        <nav className="fixed z-[9999] w-full bg-blue-900">
          <div className="py-2 max-w-[1400px] mx-auto my-0 px-4 md:px-10 flex items-center justify-between text-white font-bold text-center">
            <span className="display-none md:display-block" style={{ width: "160px" }}>
              <img src="https://pacte.confemen.org/wp-content/uploads/sites/4/2022/08/PACTE-logo.png" />
            </span>
            CARTOGRAPHIE DES BESOINS EN EDUCATION{" "}
            <span className="hidden md:contents">
              DANS LES ETATS ET GOUVERNEMENTS MEMBRES DE LA CONFEMEN
            </span>
            <div className="flex items-center">
              {/* Navbar to be displayed on large screen */}
              <ul className="items-center hidden md:flex">
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("mapping")}
                    title="Mapping"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style={{ fill: "currentColor" }}
                    >
                      <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path>
                      <path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                    </svg>
                  </a>
                </li>
                <li className="transition-pacofide border-b-[3px] border-transparent hover:border-white">
                  <a
                    href="#"
                    className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                    onClick={() => navigate("actors")}
                    title="Acteurs"
                  >
                    <UserGroupIcon className="w-6 h-6 text-white" />
                  </a>
                </li>
              </ul>

              {/* Navigation on small screen */}
              <div className="block md:hidden transition-pacofide border-b-[3px] border-transparent hover:border-white group">
                <a
                  href="#"
                  className="flex items-center px-2 py-4 space-x-4 text-base font-normal text-white sm:px-5 sm:py-5 transition-pacofide"
                  onClick={() => {
                    switchMobileMenu();
                  }}
                  onPointerLeave={() =>
                    mobileMenu.current?.classList.remove("group-hover:grid")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ fill: "currentColor" }}
                  >
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                  </svg>
                </a>
                <ul
                  ref={mobileMenu}
                  className="pacofide-nav-dropdown-menu group-hover:pointer-events-auto group-hover:top-[70px] group-hover:sm:top-[67px] sm:left-[72%]"
                >
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      onClick={() => navigateAndHide("mapping")}
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                    >
                      <span className="flex items-center justify-center bg-gray-100 rounded-full w-11 h-11">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style={{ fill: "currentColor" }}
                          className="w-8 h-8 text-slate-500"
                        >
                          <path d="M19 4h-3V2h-2v2h-4V2H8v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 20V7h14V6l.002 14H5z"></path>
                          <path d="M7 9h10v2H7zm0 4h5v2H7z"></path>
                        </svg>
                      </span>
                      <span className="pacofide-nav-dropdown-link-inner">Mapping</span>
                    </a>
                  </li>
                  <li className="pacofide-nav-dropdown-item">
                    <a
                      href="#"
                      className="flex flex-col items-center justify-center p-2 space-x-2 rounded-md hover:bg-gray-300 transition-pacofide"
                      onClick={() => navigateAndHide("actors/")}
                    >
                      <UserGroupIcon className="w-12 h-12 text-slate-500" />
                      <span className="pacofide-nav-dropdown-link-inner">Acteurs</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Here comes download modal */}
      <div
        className={`${
          openDownloads ? "" : "hidden"
        } lc-modal fixed left-1/2 top-1/2 -translate-x-2/4 -translate-y-2/4 max-w-[350px] sm:max-w-full sm:min-w-[400px] bg-white p-5 z-[99999] transition-pacofide`}
        data-lc-target="download"
      >
        <span
          className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-slate-500 text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
          data-lc-target="#download"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "currentColor" }}
            onClick={() => setOpenDownloads(false)}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </span>

        <div className="mt-14 space-y-12 max-h-[564px] overflow-auto">
          <div className="relative">
            <h3 className="text-xl font-medium text-slate-500">
              Base de données {getFiliereFromValue(selectedFiliere)?.name}
            </h3>
            <div>
              <div className="block w-full overflow-x-auto rounded-md">
                <table className="w-full mt-6 border border-collapse border-slate-400">
                  <tbody>
                    <tr className="text-xs text-slate-500 odd:bg-white even:bg-slate-50">
                      <td className="px-3 py-2.5 font-medium border border-slate-300 whitespace-nowrap">
                        Activités PACOFIDE & autres projets
                      </td>
                      <td className="px-3 py-2.5 border border-slate-300">
                        <div className="flex items-center justify-center">
                          <button
                            type="button"
                            className="bg-blue-500 hover:bg-blue-600 shadow-md text-white text-[12px] font-medium px-4 py-1.5 rounded-md transition-pacofide"
                            onClick={() =>
                              downloadFile(
                                `activities/?filiere=${selectedFiliere}`,
                                "Activités.xlsx",
                              )
                            }
                          >
                            Télécharger
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          openDownloads ? "" : "hidden"
        } lc-modal-overlay fixed left-0 top-0 h-full w-full bg-black/50 z-[9999] transition-pacofide`}
        data-lc-target="download"
        onClick={() => setOpenDownloads(false)}
      ></div>

      <div
        className={`overlay-inner absolute right-[40px] top-[150px] z-500 ${
          location.pathname == "/graph" ? "" : "hidden"
        }`}
      >
        <IndicateurAnalyseButton onClick={openAnalyse} />
      </div>
      {/* <ModalAnalyse
        isOpen={isAnalyzeOpen}
        onClose={closeAnalyse}
        selectedFiliere={getFiliereFromValue(selectedFiliere)}
        selectIntervention={setSelectedIntervention}
      /> */}
    </>
  );
}
