/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import concernedAreas from "../../shapes/countries.json";
import {
  MAP_VIEWS,
  LAYER_CONTROLS,
  MAP_FEATURE_TYPES,
  Layers,
  weightStyle,
} from "../../core/constants";
import { API_URL } from "../../services/api/axios";
import communes from "../../shapes/brute_data.json";
import { getLayers, retrieveLayer } from "../../services/api/data/layer_services";
import { useDebounce } from "use-debounce";
import { PopupComponent } from "./MappingMarker";
import { PopupAreaComponent } from "./PopupAreaComponent";
import IndicateurSuivi from "../../core/components/IndicateurSuivi";
import IndicateurSuiviButton from "../../core/components/IndicateurSuiviButton";
import IndicateurSupperpositionButton from "../../core/components/IndicateurSupperpositionButton";
import ModalSupperposition from "../../core/components/ModalSupperposition";
import { downloadFile } from "../../core/components/Navbar";
import Departement, { Commune } from "../../models/Departement_model";
import Pole from "../../models/Pole_model";
import Country from "../../models/Country";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

function MappingBased(props) {
  const { filiere } = props;

  const [viewIndex, setCurrentViewIndex] = useState(0);
  const bounds = L.geoJSON(concernedAreas).getBounds();
  const [view, setView] = useState(MAP_VIEWS[viewIndex]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[viewIndex].id);
  const [layers, setLayers] = useState(Layers);
  const [projects, setProjects] = useState([]);
  const [params, setParams] = useState();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [map, setMap] = useState();
  const [inputValue, setInputValue] = React.useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const [currentLayer, setCurrentLayer] = useState();

  const [isIndicOpen, setIsIndicOpen] = useState(true);
  const [isSupOpen, setIsSupOpen] = useState(true);
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState();
  const [countries, setCountries] = useState();

  useEffect(() => {
    if (params === undefined) {
      setCountries([]);
      return;
    }
    if (params) {
      Country.fetchData(params).then((response) => {
        setCountries(Country.fromJsonList(response.data.results));
      });
    }
  }, [params]);

  const closeIndic = () => {
    setIsIndicOpen(false);
  };
  const openIndic = () => {
    setIsIndicOpen(true);
  };

  const closeSup = () => {
    setIsSupOpen(false);
  };
  const openSup = () => {
    setIsSupOpen(true);
  };

  const updateMarkerPerControlLayer = (markers) => {
    let newMarkerPerControlLayer = {
      layerControlId: 1,
      markers: markers,
    };

    setMarkerPerControlLayer([newMarkerPerControlLayer]);
  };

  const changeMapSupperposition = (newIndex) => {
    setCurrentViewIndex(newIndex);
  };

  useEffect(() => {}, [markerPerControlLayer]);

  const onCarteNameChanged = (e) => {
    setInputValue(e);
    const params = new URLSearchParams();
    params.append("name", inputValue);
    requestTogetLayers(params);
  };

  useEffect(() => {
    setView(MAP_VIEWS[viewIndex]);
    setTileKey(MAP_VIEWS[viewIndex].id);
  }, [viewIndex]);

  const requestTogetLayers = async (params) => {
    await getLayers(params).then((response) => {
      const data = response.data.results;
      const layersToSet = data.map((layer) => {
        const { id, name, image, description, geojson } = layer;
        return {
          id,
          name,
          image,
          description,
          geojson,
        };
      });
      setLayers(layersToSet);
    });
  };

  const getUniqueLayer = (params) => {
    retrieveLayer(params).then((response) => {
      const layerToSet = response.data;
      setCurrentLayer(layerToSet);
    });
  };

  const updateLayer = (layer) => {
    getUniqueLayer(layer.id);
  };

  const shapes = {
    name: "Communes",
    layer: communes,
    style: weightStyle,
    api: "communes",
    selectValue: "commune",
  };

  const bindTooltip = function (feature, layer) {
    layer
      .bindTooltip(feature.properties.name, {
        permanent: true,
        direction: "center",
        className: "map-labels",
      })
      .openTooltip();
  };

  return (
    <>
      <div
        className={`overlay-inner absolute left-[40px] top-[150px] z-500 ${
          isIndicOpen ? "hidden" : ""
        }`}
      >
        <IndicateurSuiviButton onClick={openIndic} />
      </div>
      <IndicateurSuivi
        isOpen={isIndicOpen}
        onClose={closeIndic}
        actionSetMarkers={updateMarkerPerControlLayer}
        selectedFiliere={filiere}
        paramsSetter={setParams}
      />
      <div
        className={`overlay-inner absolute right-[40px] top-[150px] z-500 ${
          isSupOpen ? "hidden" : ""
        }`}
      >
        <IndicateurSupperpositionButton onClick={openSup} />
      </div>
      <ModalSupperposition
        isOpen={isSupOpen}
        onClose={closeSup}
        action={changeMapSupperposition}
        currentIndex={viewIndex}
      />
      <div className="h-screen w-screen relative" style={{ zIndex: 1 }}>
        <div
          className="grow h-full w-full pt-[67px]"
          style={{
            display: "grid",
          }}
        >
          <MapContainer bounds={bounds} zoomControl={false}>
            {countries && (
              <FeatureGroup>
                {concernedAreas.features.map((feature, index) => {
                  let dataJson = feature;
                  let currentCountry = null;
                  if (
                    countries.some((country) => {
                      if (country.code === dataJson.properties.code) {
                        currentCountry = country;
                        return true;
                      }
                      return false;
                    })
                  ) {
                    return (
                      <FeatureGroup key={`${dataJson.properties.name}-${index}`}>
                        {
                          <GeoJSON
                            data={dataJson}
                            style={{ color: ["yellow", "green"][viewIndex] }}
                          />
                        }
                        {currentCountry && (
                          <PopupAreaComponent
                            dataJson={currentCountry.planning}
                            countryName={currentCountry.name}
                            countryFlag={currentCountry.flag}
                            downloadLink={`countries/${currentCountry.pk}/report/`}
                            fileName={`Récapitulatif-Besoins-${currentCountry.name}.pdf`}
                          />
                        )}
                      </FeatureGroup>
                    );
                  }
                })}
              </FeatureGroup>
            )}
            {currentLayer && currentLayer.geojson && (
              <GeoJSON key={currentLayer.id} data={currentLayer.geojson} />
            )}

            <GeoJSON data={concernedAreas} onEachFeature={bindTooltip} />
            <TileLayer key={tileKey} {...view.tileLayerInfos} />
          </MapContainer>
          {/* Add a floating div at the bottom right of the screen */}
          <div className="absolute bottom-0 right-0 z-[999]">
            {/* Image of our logo */}
            <img
              src="https://kf.2mkouas.com/static/images/project-logo.svg"
              alt="Logo"
              className="h-6"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MappingBased;
