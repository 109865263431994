import { useState, useEffect } from "react";
import SelectComponent from "./SelectComponent";
import {
  years,
  priorizationLevels,
  yesOrNoOptions,
  defaultCountries,
  ressourcesLevels,
} from "../constants";
import Thematic from "../../models/Thematic";
import Need from "../../models/Need";
import { yeartoSelectOption } from "../helpers";
import SubThematic from "../../models/SubThematic_model";

export default function IndicateurSuivi(props: any) {
  const { isOpen, onClose, paramsSetter } = props;

  const [subthematics, setSubThematics] = useState<SubThematic[]>([]);
  const [thematics, setThematics] = useState<Thematic[]>([]);
  const [needs, setNeeds] = useState<Need[]>([]);
  const [selectedThematic, setSelectedThematic] = useState<any>();
  const [selectedSubThematic, setSelectedSubThematic] = useState<any>();
  const [selectedNeeds, setSelectedNeeds] = useState<any>();
  const [doNeedPlan, setDoNeedPlan] = useState<any>();
  const [prioritization, setPrioritization] = useState<any>();
  const [selectedYear, setSelectedYear] = useState<any>();
  const [selectedRessource, setSelectedRessource] = useState<any>();

  const notNull = (value: any) => {
    return value !== null && value !== undefined;
  };

  const hierarchyList = [
    setSelectedThematic,
    setSelectedSubThematic,
    setSelectedNeeds,
    setDoNeedPlan,
    setPrioritization,
    setSelectedYear,
    setSelectedRessource,
  ];

  /**
   * When a choice is made on a select the following selects
   * in the form must be reset
   * This method aims to reset the associated state variables
   * @param fromIndex Index when to start the reset from
   */
  const resetVariables = (fromIndex: number) => {
    if (fromIndex < 3) {
      paramsSetter(undefined);
    }
    for (let index = fromIndex; index < hierarchyList.length; index++) {
      const setter = hierarchyList[index];
      setter(undefined);
    }
  };

  const changeVariable = (value: any, setterIndex: number) => {
    hierarchyList[setterIndex](value);
    resetVariables(setterIndex + 1);
  };

  useEffect(() => {
    Thematic.fetchData().then((response: any) => {
      setThematics(Thematic.fromJsonList(response.data.results));
    });
    resetVariables(0);
    setSelectedThematic(null);
  }, []);

  useEffect(() => {
    if (notNull(selectedThematic)) {
      let params = new URLSearchParams();
      if (selectedThematic) params.append("parent", selectedThematic);
      SubThematic.fetchData(params).then((response: any) => {
        setSubThematics(SubThematic.fromJsonList(response.data.results));
      });
    }
  }, [selectedThematic]);

  useEffect(() => {
    if (notNull(selectedSubThematic)) {
      let params = new URLSearchParams();
      if (selectedThematic) params.append("thematic", selectedThematic);
      if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
      Need.fetchData(params).then((response: any) => {
        setNeeds(Need.fromJsonList(response.data.results));
      });
    }
  }, [selectedSubThematic]);

  useEffect(() => {
    if (!notNull(selectedNeeds)) return;
    let params = new URLSearchParams();
    if (selectedThematic) params.append("thematic", selectedThematic);
    if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
    if (selectedNeeds) params.append("need", selectedNeeds);
    paramsSetter(params);
  }, [selectedNeeds]);

  useEffect(() => {
    if (!notNull(doNeedPlan)) return;
    let params = new URLSearchParams();
    if (selectedThematic) params.append("thematic", selectedThematic);
    if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
    if (selectedNeeds) params.append("need", selectedNeeds);
    params.append("is_planned", doNeedPlan);
    paramsSetter(params);
  }, [doNeedPlan]);

  useEffect(() => {
    if (!notNull(prioritization)) return;
    let params = new URLSearchParams();
    if (selectedThematic) params.append("thematic", selectedThematic);
    if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
    if (selectedNeeds) params.append("need", selectedNeeds);
    if (doNeedPlan) params.append("is_planned", doNeedPlan);
    if (prioritization) params.append("priority", prioritization);
    paramsSetter(params);
  }, [prioritization]);

  useEffect(() => {
    if (selectedYear) {
      let params = new URLSearchParams();
      if (selectedThematic) params.append("thematic", selectedThematic);
      if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
      if (selectedNeeds) params.append("need", selectedNeeds);
      if (doNeedPlan) params.append("is_planned", doNeedPlan);
      if (prioritization) params.append("priority", prioritization);
      if (selectedYear) params.append("year", selectedYear);
      paramsSetter(params);
      // Country.fetchData(params).then(
      //   (response: any) => {
      //     setCountries(Need.fromJsonList(response.data.results));
      //   },
      // );
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedRessource) {
      let params = new URLSearchParams();
      if (selectedThematic) params.append("thematic", selectedThematic);
      if (selectedSubThematic) params.append("sub_thematic", selectedSubThematic);
      if (selectedNeeds) params.append("need", selectedNeeds);
      if (doNeedPlan) params.append("is_planned", doNeedPlan);
      if (prioritization) params.append("priority", prioritization);
      if (selectedYear) params.append("year", selectedYear);
      if (selectedRessource) params.append("ressources_level", selectedRessource);
      paramsSetter(params);
      // Country.fetchData(params).then(
      //   (response: any) => {
      //     setCountries(Need.fromJsonList(response.data.results));
      //   },
      // );
    }
  }, [selectedRessource]);

  useEffect(() => {
    if (selectedNeeds) {
      let params = new URLSearchParams();
      params.append("need", selectedNeeds);
      paramsSetter(params);
    }
  }, [selectedNeeds]);

  return (
    <div
      className={`overlay-inner absolute left-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
                Analyse spatiale
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-[370px]">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Thématique</span>

                    <SelectComponent
                      options={Thematic.toSelectOption(thematics)}
                      onChange={(value) => {
                        changeVariable(value, 0);
                      }}
                      customPlaceHolder="Sélectionner une thématique"
                      className="w-full"
                      value={selectedThematic}
                    />
                  </label>

                  {notNull(selectedThematic) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Sous thématiques
                      </span>

                      <SelectComponent
                        options={SubThematic.toSelectOption(subthematics)}
                        onChange={(value) => changeVariable(value, 1)}
                        customPlaceHolder="Sélectionner une Sous thématique"
                        value={selectedSubThematic}
                      />
                    </label>
                  )}

                  {notNull(selectedSubThematic) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Besoin</span>

                      <SelectComponent
                        options={Need.toSelectOption(needs)}
                        onChange={(value) => changeVariable(value, 2)}
                        customPlaceHolder="Besoin"
                        value={selectedNeeds}
                      />
                    </label>
                  )}

                  {notNull(selectedNeeds) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Besoin du plan
                        <br />
                        éducatif
                      </span>

                      <SelectComponent
                        options={yesOrNoOptions}
                        onChange={(value) => changeVariable(value, 3)}
                        customPlaceHolder="Besoin du plan éducatif"
                        value={doNeedPlan}
                      />
                    </label>
                  )}

                  {selectedNeeds && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Niveau de priorisation
                      </span>

                      <SelectComponent
                        options={priorizationLevels}
                        onChange={(value) => changeVariable(value, 4)}
                        customPlaceHolder="Niveau de priorisation"
                        value={prioritization}
                      />
                    </label>
                  )}

                  {selectedNeeds && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Année de réalisation
                      </span>

                      <SelectComponent
                        options={yeartoSelectOption(years)}
                        onChange={(value) => changeVariable(value, 5)}
                        customPlaceHolder="Année de réalisation"
                        value={selectedYear}
                      />
                    </label>
                  )}

                  {selectedNeeds && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Ressources</span>
                      <SelectComponent
                        options={ressourcesLevels}
                        onChange={(value) => changeVariable(value, 6)}
                        customPlaceHolder="Ressources"
                        value={selectedRessource}
                      />
                    </label>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
