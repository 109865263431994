import CategoryActor from "./CategoryActor";
import { Box, Tab } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { useState } from "react";

export default function ActorPage() {
  enum Category {
    PERSON = "Personnes",
    COOPERATIVE = "Coopératives",
    INSTITUTION = "Institutions",
  }

  const [value, setValue] = useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="pt-[100px] max-w-[1400px] mx-auto my-0 px-4 md:px-10 flex items-center justify-between">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <CategoryActor category={"Acteurs"} />
      </Box>
    </div>
  );
}
