import { Outlet } from "react-router-dom";
import Navbar, { useLocalStorageState } from "../../core/components/Navbar";
import { useEffect, useRef, useState } from "react";
import { defaultFilieres } from "../../core/constants";
import { useNavigate } from "react-router-dom";
import { apiLogout } from "../../services/api/auth/login";

export default function Dashboard() {
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState<any>();
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [filiere, setFiliere] = useLocalStorageState(
    "filiere",
    defaultFilieres[0].value,
  );

  const navigate = useNavigate();

  return (
    <div>
      <Navbar
        selectedFiliere={filiere}
        setSelectedFiliere={setFiliere}
        setSelectedIntervention={setSelectedIntervention}
      />
      <div className="bg-white-500">
        <div className="bg-white-500">
          <Outlet context={[filiere, selectedIntervention, setFiliere]} />
        </div>
      </div>
    </div>
  );
}
