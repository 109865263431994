import BaseModel from "./BaseModel";

export default class Country extends BaseModel {
  planning?: object;
  code?: string;
  flag?: string;

  constructor(
    id: number,
    name: string,
    pk?: number,
    planning?: object,
    code?: string,
    flag?: string,
  ) {
    super(id, name, pk);
    this.planning = planning;
    this.code = code;
    this.flag = flag;
  }

  static fromJson(json: any): BaseModel {
    return new this(json.id, json.name, json.pk, json.planning, json.code, json.flag);
  }

  static url = "countries";
}
