import { useOutletContext } from "react-router-dom";
import MappingBased from "../mapping/MappingBased";

export default function MainPage(props: any) {
  const { viewIndex, markerPerControlLayer } = props;
  const [filiere, intervention, setFiliere] = useOutletContext<any>();

  return (
    <div className="h-full w-full">
      <MappingBased />
    </div>
  );
}
