import BaseModel from "./BaseModel";

export default class Thematic extends BaseModel {
  static url = "thematics";

  static fetchData(params: URLSearchParams = new URLSearchParams()): any {
    params.append("parent", "null");
    return super.fetchData(params);
  }
}
