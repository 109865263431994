import BaseModel from "./BaseModel";

export class Actor extends BaseModel {
  email: string;
  phone_number: string;
  static url: string = "stakeholders";

  constructor(id: number, name: string, email: string, phone_number: string) {
    super(id, name);
    this.email = email;
    this.phone_number = phone_number;
  }

  static fromJson(json: any): Actor {
    return new this(json.id, json.name, json.email, json.phones);
  }
}
